import { use } from 'i18next';
import * as AmbassadorTypes from './ambassadorTypes';

const initalState = {
  loading: false,
  fetching: false,
  error: '',

  ambassadorReportList: { userData: [] },
  campusAmbassadorList: {},
  campusAmbassadorDetail: {},
  ambassadorBookingHistory: {},
};

// eslint-disable-next-line
const ambassadorReducer = (state = initalState, action) => {
  switch (action.type) {
    case AmbassadorTypes.LOADING_SUCCESSFUL:
      return {
        ...state,
        loading: true,
      };
    case AmbassadorTypes.FETCHING_SUCCESSFUL:
      return {
        ...state,
        fetching: true,
      };

    case AmbassadorTypes.CAMPUS_AMBASSADOR_LIST:
      return {
        ...state,
        loading: false,
        campusAmbassadorList: action.payload,
      };

    case AmbassadorTypes.LISTING_FAILED:
      return {
        ...state,
        loading: false,
        campusAmbassadorList: action.payload,
      };

    case AmbassadorTypes.AMBASSADOR_REPORT:
      return {
        ...state,
        loading: false,
        ambassadorReportList: action.payload,
      };

    case AmbassadorTypes.LISTING_RESET:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: '',
      };
    case AmbassadorTypes.LOGOUT:
      return {
        loading: false,
        error: '',
        bankList: [],
        vehicleMakeList: [],
        vehicleFeatureList: [],
      };

    default:
      return state;
  }
};

export default ambassadorReducer;
