import { logout } from '../redux/auth/authActions';
import { dispatch } from '../redux/store';
import { ENV } from '../env';

let BASE_URL;

export const MAIN_URL = ENV.onboarding;
export const BOOKINGS_URL = ENV.booking;
export const SETTINGS_URL = ENV.settings;
export const CAPTAINS_URL = ENV.captain;
export const PARTNERS_URL = ENV.partner;
export const INVENTORY_URL = ENV.inventory;
export const PROCUREMENT_URL = ENV.procurement;
export const AMBASSADOR_URL = ENV.ambassador;
export const OPERATIONS_URL = ENV.operations;

export const httpRequest = async (params) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { url, method, body, headers, urlType = 'base', isFormData = false, applyToken = true } = params;
    if (urlType.toLowerCase() === 'bookings') BASE_URL = BOOKINGS_URL;
    if (urlType.toLowerCase() === 'settings') BASE_URL = SETTINGS_URL;
    if (urlType.toLowerCase() === 'captains') BASE_URL = CAPTAINS_URL;
    if (urlType.toLowerCase() === 'partners') BASE_URL = PARTNERS_URL;
    if (urlType.toLowerCase() === 'inventory') BASE_URL = INVENTORY_URL;
    if (urlType.toLowerCase() === 'ambassador') BASE_URL = AMBASSADOR_URL;
    if (urlType.toLowerCase() === 'procurement') BASE_URL = PROCUREMENT_URL;
    if (urlType.toLowerCase() === 'operations') BASE_URL = OPERATIONS_URL;
    if (urlType.toLowerCase() === 'base') BASE_URL = MAIN_URL;

    const accessToken = localStorage?.getItem('gig-access-token') || '';
    const tokenRefresh = JSON.parse(localStorage?.getItem('gig-refresh-token')) || '';
    const accessTokenTimer = JSON.parse(localStorage?.getItem('gig-access-token-timer')) || '';

    // if (applyToken) {
    //   if (!accessToken || new Date().toISOString() > tokenRefresh?.tokenExpiration) {
    //     // session time out, call refresh token func
    //     dispatch(logout('Session timeout! Please login again!'));
    //     return false;
    //   }
    // }

    if (!url) throw new Error('url is not set');

    if (typeof url !== 'string') throw new Error('url must be a string');

    if (!navigator.onLine) throw new Error('Internet connection not found!');

    const options = {
      method: method || 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gig-access-token')}`,
        ...headers,
      },
    };

    if (isFormData) options.body = body;
    if (!isFormData) options.headers['Content-Type'] = 'application/json';

    if (body && !isFormData) options.body = JSON.stringify(body);

    // if (applyToken && new Date().toISOString() >= accessTokenTimer) {
    //   dispatch(logout('Session timeout! Please login again!'));
    //   return false;
    // }

    // IF TOKEN IS OKAY //////
    // ////////////////////////////////////////

    const res = await fetch(`${BASE_URL}/${url}`, options);

    if (res.status === 401 || res.statusText === 'Unauthorized') {
      dispatch(logout('You are not Authorized!'));
      return false;
    }

    const responseData = await res.json();
    return responseData;
  } catch (error) {
    throw error;
  }
};
