/* eslint-disable no-unused-vars */
// @mui
import { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Card,
  Box,
  TablePagination,
  FormControlLabel,
  Switch,
  DialogTitle,
  Button,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

// hooks
import { LoadingButton } from '@mui/lab';
import { method } from 'lodash';
import useSettings from '../../../../hooks/useSettings';

// components
import Page from '../../../../components/Page';
// sections
import { FilterToolBar } from '../../../../sections/@dashboard/user/list';
import { useDispatch, useSelector } from '../../../../redux/store';
import CaptainBonusForm from './forms/CaptainBonusForm';
import CaptainBonusTable from './tables/CaptainBonusTable';
import { DialogAnimate } from '../../../../components/animate';
import * as ModalTypes from '../../../../redux/modal/modalTypes';
import CreateBonusForm from './forms/CreateBonusForm';
import Iconify from '../../../../components/Iconify';
import DeductionsImportForm from './forms/DeductionsImportForm';
import { httpRequest } from '../../../../https/http';
import { searchCaptainDeduction } from '../../../../redux/captains/captainActions';
import BonusApprovalModal from './forms/BonusApprovalModal';

// ----------------------------------------------------------------------

export default function CaptainBonuses() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const { filteredData } = useSelector((state) => state.search);
  const { isOpenModal, modalType, modalData } = useSelector((state) => state.modal);
  const [deleting, setDeleting] = useState(false);

  const {
    user: { rolesDTOs },
  } = useSelector((state) => state.auth);
  const currentRole = rolesDTOs.map((r) => r.name);

  const handleCloseModal = () => {
    dispatch({ type: ModalTypes.MODAL_CLOSE });
  };
  const handleAddEvent = (action) => {
    dispatch({ type: ModalTypes.MODAL_OPEN, payload: action });
  };

  const today = new Date();
  const nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + 1);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(nextDay);
  const [captainCode, setCaptainCode] = useState('');

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [dense, setDense] = useState(false);
  const paginated = true;

  const { captainDeductionList, loading } = useSelector((state) => state.captains);

  useEffect(() => {
    if (captainDeductionList?.userData?.length) {
      const { currentPage, totalPages, pageSize, totalCount, userData = [] } = captainDeductionList;
      setCurrentPage(currentPage - 1);
      setPageSize(pageSize);
      setTotalPage(totalPages);
      setTotalCount(totalCount);
      setUserData(userData);
    } else {
      setUserData([]);
    }
  }, [captainDeductionList]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    dispatch(
      searchCaptainDeduction(
        enqueueSnackbar,
        captainCode,
        new Date(startDate).toISOString(),
        new Date(endDate).toISOString(),
        newPage + 1,
        pageSize,
        paginated
      )
    );
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setCurrentPage(0); // Reset to the first page
    dispatch(
      searchCaptainDeduction(
        enqueueSnackbar,
        captainCode,
        new Date(startDate).toISOString(),
        new Date(endDate).toISOString(),
        1,
        newPageSize,
        paginated
      )
    );
  };

  const onChangeDense = () => {
    setDense(!dense);
  };

  useEffect(() => {
    setUserData(filteredData);
  }, [filteredData]);

  const deleteBonusHandular = async (routeId) => {
    try {
      setDeleting(true);
      const result = await httpRequest({
        url: `CaptainBonus/DeleteCaptainBonus`,
        urlType: 'captains',
        method: 'POST',
        body: {
          deleteCaptainBonusId: [modalData?.id],
          reason: 'No longer Needed',
        },
      });

      if (result.code === 1) {
        dispatch(
          searchCaptainDeduction(
            enqueueSnackbar,
            captainCode,
            new Date(startDate).toISOString(),
            new Date(endDate).toISOString()
          )
        );
        handleCloseModal();
        if (result?.shortDescription) enqueueSnackbar(result?.shortDescription, { variant: 'success' });
      } else if (result?.shortDescription) enqueueSnackbar(result?.shortDescription, { variant: 'error' });
      setDeleting(false);
    } catch (error) {
      setDeleting(false);
      enqueueSnackbar('Something went wrong, pls try again!', { variant: 'error' });
    }
    return true;
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <FilterToolBar
          isFilter={false}
          placeholder="Search All Bonuses..."
          title="All Captains Bonuses"
          subheader="Other Incomes Details"
          buttonDetails={{ isButton: true, label: 'Create', icon: 'plus-outline' }}
          csvHeader={[]}
          exportData={userData}
          exportFileName="advanceBookingReportDetail"
          isShowExportBtn={false}
          filterKeys={['bookingReferenceCode', 'vehicleRegNumber', 'employeeName']}
        />

        <Grid item xs={12}>
          <Card sx={{ px: 3, mt: 2, pt: 2 }}>
            <CaptainBonusForm
              captainCode={captainCode}
              startDate={startDate}
              endDate={endDate}
              setCaptainCode={setCaptainCode}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12}>
                <Box>
                  {userData?.length > 1 ? (
                    <Box mb={2} ml={2}>
                      <LoadingButton
                        style={{ marginRight: 8 }}
                        type="button"
                        onClick={() => {
                          if (
                            !['Administrator', 'Auditor', 'AuditSupervisor'].some((role) => currentRole.includes(role))
                          ) {
                            return enqueueSnackbar('User does not have permission/role to approve this!', {
                              variant: 'error',
                            });
                          }
                          dispatch({
                            type: ModalTypes.MODAL_OPEN,
                            payload: {
                              type: 'approve_decline',
                              data: {
                                selectedType: 'approve',
                                selectedIds: userData?.map((d) => d?.id),
                              },
                            },
                          });
                        }}
                        variant="contained"
                        loading={false}
                        color="info"
                      >
                        <Iconify icon={'eva:checkmark-square-2-outline'} sx={{ marginRight: 1, fontSize: '18px' }} />{' '}
                        AUDIT: Approve All
                      </LoadingButton>
                      <LoadingButton
                        style={{ marginRight: 8 }}
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: ModalTypes.MODAL_OPEN,
                            payload: {
                              type: 'approve_decline',
                              data: {
                                selectedType: 'approve',
                                selectedIds: userData?.map((d) => d?.id),
                                isAudit: true,
                              },
                            },
                          })
                        }
                        variant="contained"
                        loading={false}
                        color="warning"
                      >
                        <Iconify icon={'eva:checkmark-square-2-outline'} sx={{ marginRight: 1, fontSize: '18px' }} />{' '}
                        CRM: Approve All
                      </LoadingButton>
                      <LoadingButton
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: ModalTypes.MODAL_OPEN,
                            payload: {
                              type: 'approve_decline',
                              data: {
                                selectedType: 'decline',
                                selectedIds: userData?.map((d) => d?.id),
                                isAudit: false,
                              },
                            },
                          })
                        }
                        variant="contained"
                        loading={false}
                      >
                        <Iconify icon={'eva:close-fill'} sx={{ marginRight: 1, fontSize: '18px' }} /> Decline All
                      </LoadingButton>
                    </Box>
                  ) : (
                    ''
                  )}
                  <CaptainBonusTable
                    title="Deduction Details"
                    loading={loading}
                    dense={dense}
                    serialNo={currentPage * pageSize + 1}
                    tableData={userData || []}
                    tableLabels={[
                      { id: 'sn', label: 'S/N', align: 'center' },
                      { id: 'captainCode', label: 'Captain Code', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'remarks', label: 'Remarks', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'amount', label: 'Amount', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'dateCreated', label: 'Date Created', whiteSpace: 'nowrap' },
                      { id: 'dateModified', label: 'Date Modified', align: 'center', whiteSpace: 'nowrap' },
                      { id: 'approvalStatus', label: 'Approval Status', align: 'center', whiteSpace: 'nowrap' },
                      // { id: 'changeStatus', label: 'Change Status', align: 'center' },
                      { id: 'approveReject', label: 'Approve/Rejected By', align: 'center' },
                      { id: 'approvalComment', label: `CRM's Comment`, align: 'center' },
                      { id: 'approvalComment', label: `Auditor's Comment`, align: 'center' },
                      { id: 'dateApproved', label: 'Date Approved/Rejected', align: 'center' },
                      { id: 'action', label: 'Action', align: 'center' },
                    ]}
                  />
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 500, 1000]}
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                  <FormControlLabel
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    label="Dense"
                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Card>
          <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
            {modalType === 'create' && (
              <DialogTitle sx={{ backgroundColor: '#AA1D00', color: 'white', m: 0, pb: 2 }}>Add Cash/Bonus</DialogTitle>
            )}
            {modalType === 'import' && (
              <DialogTitle
                sx={{ backgroundColor: '#AA1D00', color: 'white', m: 0, pb: 2, border: '5px solid #AA1D00' }}
              >
                Upload File
              </DialogTitle>
            )}
            {modalType === 'delete' && (
              <DialogTitle
                sx={{ backgroundColor: '#AA1D00', color: 'white', m: 0, pb: 2, border: '5px solid #AA1D00' }}
              >
                Delete Bonus
              </DialogTitle>
            )}
            {modalType === 'approve_decline' && (
              <DialogTitle
                sx={{ backgroundColor: '#AA1D00', color: 'white', m: 0, pb: 2, border: '5px solid #AA1D00' }}
              >
                {modalData?.selectedType === 'approve' ? 'Approve' : 'Decline'} Deduction
              </DialogTitle>
            )}

            <hr style={{ backgroundColor: '#AA1D00', color: 'white', m: 0, p: 0 }} />

            {modalType === 'create' && (
              <CreateBonusForm
                modalData={{}}
                // userType={userType}
                // terminalList={terminalList}
                handleCloseModal={handleCloseModal}
                isCreateStore
              />
            )}
            {modalType === 'delete' && (
              <Grid container spacing={3} sx={{ px: 3 }}>
                <Grid item xs={12}>
                  <Box my={2}>
                    <Typography fontWeight="bold" sx={{ color: 'gray' }}>
                      Captain Code: {modalData?.captainCode}
                    </Typography>
                    <Typography fontWeight="bold" mt={1}>
                      Are you sure you want to delete this?
                    </Typography>
                  </Box>

                  <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button sx={{ mr: 1 }} variant="outlined" color="error" onClick={handleCloseModal}>
                      Close
                    </Button>
                    <LoadingButton
                      type="button"
                      onClick={() => deleteBonusHandular(modalData?.selectedIds)}
                      variant="contained"
                      loading={deleting}
                    >
                      Confirm
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            )}
            {modalType === 'approve_decline' && (
              <BonusApprovalModal
                modalData={modalData}
                captainCode={captainCode}
                startDate={startDate}
                endDate={endDate}
              />
            )}

            <Box display="flex" justifyContent="flex-end" sx={{ padding: 3 }}>
              {modalType === 'view' && (
                <Button variant="outlined" color="error" onClick={handleCloseModal}>
                  Close
                </Button>
              )}
            </Box>
          </DialogAnimate>
        </Grid>
      </Container>
    </Page>
  );
}
