import { httpRequest } from '../../https/http';
import * as userTypes from './userTypes';

export const getUsersAction =
  (enqueueSnackbar, filterInfo = { paginated: false }, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    try {
      dispatch({ type: userTypes.FETCHING_USER });
      let filteredURL = `Accounts/users?Paginated=${filterInfo.paginated}&Descending=true`;
      if (filterInfo?.paginated) {
        filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      }
      if (filterInfo?.roleId) filteredURL = `${filteredURL}&RoleId=${filterInfo?.roleId}`;
      // if (filterInfo.userType || filterInfo.userType === 0)
      // filteredURL = `${filteredURL}&userType=${Number(filterInfo?.userType)}`;
      if (filterInfo?.search) filteredURL = `${filteredURL}&search=${filterInfo?.search}`;
      if (filterInfo?.parameter) filteredURL = `${filteredURL}&parameter=${filterInfo?.parameter}`;

      const result = await httpRequest({
        url: filteredURL,
      });

      if (result?.code === 1) {
        dispatch({ type: userTypes.FETCHED_USER_SUCCESS, payload: result?.data });
      } else if (result?.shortDescription) {
        if (result.shortDescription)
          enqueueSnackbar(result.shortDescription || 'Something went wrong', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('oops !!! something went wrong, please try again.', { variant: 'error' });
    } finally {
      dispatch({ type: userTypes.CLEAR_USERS_ERROR });
    }
  };

export const getUsersByTypeAction =
  ({ userType, pageNumber, pageSize }, enqueueSnackbar) =>
  async (dispatch) => {
    try {
      dispatch({ type: userTypes.FETCHING_USER });
      const result = await httpRequest({
        // url: `Accounts/users?UserType=${userType}&Paginated=true&pageNumber=${pageNumber}&pageSize=${pageSize}&Descending=true`,
        url: `Accounts/users?UserType=1&Descending=false&Paginated=false`,
      });
      if (result?.code === 1) {
        dispatch({ type: userTypes.FETCHED_USER_TYPE_SUCCESS, payload: result?.data });
      } else if (result?.shortDescription) {
        if (result.shortDescription)
          enqueueSnackbar(result.shortDescription || 'Something went wrong', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('oops !!! something went wrong, please try again.', { variant: 'error' });
    } finally {
      dispatch({ type: userTypes.CLEAR_USERS_ERROR });
    }
  };
export const getUsersDetailsAction =
  (enqueueSnackbar, userName = '') =>
  async (dispatch) => {
    dispatch({ type: userTypes.FETCHING_USER });
    // let filteredURL = `Accounts/user?username=${userName}`;

    try {
      const result = await httpRequest({
        url: `Accounts/user?username=${userName}`,
        // urlType: 'settings',
      });
      if (result.code === 1) {
        dispatch({ type: userTypes.FETCHING_USER_DETAILS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: userTypes.CLEAR_USERS_ERROR });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: userTypes.CLEAR_USERS_ERROR });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getUsersByTerminalAction =
  (enqueueSnackbar, terminalId = '') =>
  async (dispatch) => {
    dispatch({ type: userTypes.FETCHING_USER });

    try {
      const result = await httpRequest({
        url: `Accounts/users?TerminalId=${terminalId}&Paginated=false`,
        // urlType: 'settings',
      });
      if (result.code === 1) {
        dispatch({ type: userTypes.FETCHING_USER_BY_TERMINAL, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: userTypes.CLEAR_USERS_ERROR });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: userTypes.CLEAR_USERS_ERROR });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getCustomerRecordsByAction =
  (enqueueSnackbar, parameter = '', value = '', pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: userTypes.FETCHING_USER });
    let filteredURL = `Customer/users?Descending=true`;
    if (parameter) filteredURL = `${filteredURL}&Search=${value}&Parameter=${parameter}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        // urlType: 'settings',
      });
      if (result.code === 1) {
        dispatch({ type: userTypes.CUSTOMER_RECORDS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: userTypes.CLEAR_USERS_ERROR });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: userTypes.CLEAR_USERS_ERROR });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
